<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Menu</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.mdiIcon="{ item }">
                        <v-icon>{{item.mdiIcon}}</v-icon>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'menuForm'"
                            :showButtons="{
                                edit: true
                            }"  />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";

    export default ({

        components: {
            ActionList: ActionList
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Friendly Name", value: "friendlyName", sortable: true },
                { text: "MDI Icon", value: "mdiIcon", sortable: true },
                { text: "Order", value: "order", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listMenu: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listMenu.filter((menuFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var friendlyName = menuFilter.friendlyName.toLowerCase().match(filter)
                    var mdiIcon = menuFilter.mdiIcon.toLowerCase().match(filter)
                    var order = menuFilter.order.toString().toLowerCase().match(filter)
                    var id = menuFilter.id.toString().match(filter);

                    if(friendlyName != null) { return friendlyName; } 
                    else if(mdiIcon != null) { return mdiIcon; } 
                    else if(order != null) { return order; } 
                    else { return id; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listMenu = await this.$store.dispatch("menuModule/List");
            },
        },

        created() {
            this.getRegisters();
        }
    })
</script>
